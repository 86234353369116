<div class="row">
    <div class="col-lg-12">
        <nb-card>
            <nb-card-header>
                <h5 class="heading">Approve Request</h5>
            </nb-card-header>
            <nb-card-body>
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-hover">
                    <thead class="thead">
                        <tr>
                            <th style="font-size: 11px">Select to Open</th>
                            <th style="font-size: 11px">Company Code</th>
                            <th style="font-size: 11px">Asset No.</th>
                            <th style="font-size: 11px">Approver Status</th>
                            <th style="font-size: 11px">Serial No.</th>
                            <th style="font-size: 11px">Tag No</th>
                            <th style="font-size: 11px">Employee Code</th>
                            <th style="font-size: 11px">Department</th>
                            <th style="font-size: 11px">Movement Type</th>
                            <th style="font-size: 11px">Acknowledge By Store</th>
                            <th style="font-size: 11px">Acknowledge By Recipent</th>
                            <th style="font-size: 11px">Expected Return Date</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr id="searchval">
                            <td><input nbInput fieldSize="tiny" class="textboxclass" type="checkbox" id="checkBox"
                                    disabled></td>
                            <td><input nbInput fieldSize="tiny" class="textboxclass" type="text" id="companyId"
                                    value="{{companyId}}" disabled style="color: black"/>
                                <!--style="background-color: gray;color: white" disabled-->
                            </td>
                            <td><input nbInput fieldSize="tiny" class="textboxclass" type="text" id="childAssetNo"></td>
                            <td><select class="textboxclass1" id="approverStatus">
                                    <option value="Submitted">Submitted</option>
                                    <option value="On Hold">On Hold</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Shipped">Shipped</option>
                                    <option value="Reject">Reject</option>
                                    <!-- <option value="Delivered">Delivered</option> -->
                                </select>
                            </td>
                            <td><input nbInput fieldSize="tiny" class="textboxclass" type="text" id="serialNo"></td>
                            <td><input nbInput fieldSize="tiny" class="textboxclass" type="text" id="tagNo"></td>
                            <td><input nbInput fieldSize="tiny" class="textboxclass" type="text" id="employeeId"></td>
                            <td><input nbInput fieldSize="tiny" class="textboxclass" type="text" id="department"></td>
                            <td><select class="textboxclass1" id="movementType">
                                    <option></option>
                                    <option value="Work From Home">Work From Home</option>
                                    <option value="Internal Returnable">Internal Returnable</option>
                                    <option value="Internal Non-Returnable" style="width: 200px">Internal Non-Returnable
                                    </option>
                                    <option value="External Returnable" style="width: 200px">External Returnable
                                    </option>
                                    <option value="External Non Returnable" style="width: 200px">External Non Returnable
                                    </option>
                                </select>
                            </td>
                            <td><select class="textboxclass1" id="acknowledgeBy">
                                    <option></option>
                                    <option value="True">True</option>
                                    <option value="False">False</option>
                                </select>
                            </td>
                            <td><select class="textboxclass1" id="acknowledgeByRecipent">
                                    <option></option>
                                    <option value="True">True</option>
                                    <option value="False">False</option>
                                </select>
                            </td>
                            <td><input nbInput fieldSize="tiny" class="textboxclass" type="date" id="expectedReturnDate"
                                    disabled></td>
                        </tr>


                        <tr *ngFor="let cid of movementApprovalArray">

                            <!-- <td><input type="checkbox" id="checkBox" [checked]="flag"  (change)="Check(cid.id)"></td>
                    <td><input type="text" value="{{cid.companyId}}" disabled></td>
                    <td><input type="text" value="{{cid.childAssetNo}}" disabled></td>
                    <td><input type="text" value="{{cid.approverStatus}}" disabled></td>
                    <td><input type="text" value="{{cid.serialNo}}" disabled></td>
                    <td><input type="text" value="{{cid.tagNo}}" disabled></td>
                    <td><input type="text" value="{{cid.employeeId}}" disabled></td>
                    <td><input type="text" value="{{cid.empDepartment}}" disabled></td>
                    <td><input type="text" value="{{cid.transferType}}" disabled></td>
                    <td><input type="text" value="{{cid.acknowledgedBy}}" disabled></td>
                    <td><input type="text" value="{{cid.acknowledgedByRecipient}}" disabled></td>
                    <td><input type="date" value="{{cid.expectedReturnDate}}" disabled></td>
                             -->



                            <td><input type="checkbox" id="checkBox" (change)="Check(cid.id)"></td>
                            <td>{{cid.companyId}}</td>
                            <td>{{cid.childAssetNo}}</td>
                            <td>{{cid.approverStatus}}</td>
                            <td>{{cid.serialNo}}</td>
                            <td>{{cid.tagNo}}</td>
                            <td>{{cid.employeeId}}</td>
                            <td>{{cid.empDepartment}}</td>
                            <td>{{cid.transferType}}</td>
                            <td>{{cid.acknowledgedBy}}</td>
                            <td>{{cid.acknowledgedByRecipient}}</td>
                            <td>{{cid.expectedReturnDate | date: 'dd-MM-yyyy'}}</td>

                        </tr>

                    </tbody>
                </table>
                <button (click)="Search()" class="btn btn-secondary btn-sm">Search</button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button (click)="Open()" class="btn btn-secondary btn-sm">Open</button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button type="button" (click)="approvedAll()" id="approve" class="btn btn-secondary btn-sm">Approve
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button type="button" (click)="rejectAll()" id="reject" class="btn btn-secondary btn-sm">Reject
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button type="button" (click)="sendReminder()" id="reminder" class="btn btn-secondary btn-sm">Send
                    Reminder</button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button type="button" (click)="approve()" class="btn btn-secondary btn-sm" hidden>Testing</button>
            </nb-card-body>
        </nb-card>
    </div>
</div>